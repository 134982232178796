.weather {
    width: 400px;
    border-radius: 6px;
    color: #fff;
    background-color: #395a96;
    margin: 20px auto 0 auto;
    padding: 0 20px 20px 20px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    opacity: 0.7;
    box-shadow: 0px 10px 25px 3px #395a96;
}

.weather:hover {
    transform: scale(1.02);
    background-color: #2e7ac2;
    box-shadow: 0px 10px 25px 6px #2e7ac2;
    opacity: 1;
   
}

.top, .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.city {
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 2px;
    margin: 0;
}

.weather-description {
    font-weight: 900;
    font-size: 13px;
    line-height: 1;
    margin: 5px;
}

.weather-icon {
    width: 100px;
    border-radius: 50%;
    transition: 1s all;
}


.temperature {
    font-weight: 600;
    font-size: 70px;
    width: auto;
    letter-spacing: -3px;
    margin: 10px 0;
}
.temperature:hover {
    color: rgb(104, 80, 44);
    transition: 0.5s all ease-in-out;
    text-shadow: 0px   0px 15px white;

}

.details {
    width: 100%;
    padding-left: 40px;
    
}

.parameter-row {
    display: flex;
    justify-content: space-between;

}

.parameter-label {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
}

.parameter-value {
    text-align: right;
    font-family: 600;
    font-size: 12px;
}

.parameter-label.top {
    border-bottom: 1px solid #fff;
}